/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.antzoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #1f1f1f;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(255, 255, 255, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(255, 255, 255, 0.85);
  background: #1f1f1f;
  border-bottom: 1px solid #303030;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #303030;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #a61d24;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #d89614;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #177ddc;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #49aa19;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-modal .ant-picker-clear,
.ant-modal .ant-slider-handle,
.ant-modal .ant-anchor-wrapper,
.ant-modal .ant-collapse-content,
.ant-modal .ant-timeline-item-head,
.ant-modal .ant-card {
  background-color: #1f1f1f;
}
.ant-modal .ant-transfer-list-header {
  background: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}
.ant-modal .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.ant-modal tr.ant-table-expanded-row > td,
.ant-modal tr.ant-table-expanded-row:hover > td {
  background: #272727;
}
.ant-modal .ant-table.ant-table-small thead > tr > th {
  background-color: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}
.ant-modal .ant-table {
  background-color: #1f1f1f;
}
.ant-modal .ant-table .ant-table-row-expand-icon {
  border: 1px solid #3a3a3a;
}
.ant-modal .ant-table tfoot > tr > th,
.ant-modal .ant-table tfoot > tr > td {
  border-bottom: 1px solid #3a3a3a;
}
.ant-modal .ant-table thead > tr > th {
  background-color: #272727;
  border-bottom: 1px solid #3a3a3a;
}
.ant-modal .ant-table tbody > tr > td {
  border-bottom: 1px solid #3a3a3a;
}
.ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-left,
.ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-right {
  background-color: #1f1f1f;
}
.ant-modal .ant-table tbody > tr.ant-table-row:hover > td {
  background: #303030;
}
.ant-modal .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #3a3a3a;
}
.ant-modal .ant-table.ant-table-bordered thead > tr > th,
.ant-modal .ant-table.ant-table-bordered tbody > tr > td,
.ant-modal .ant-table.ant-table-bordered tfoot > tr > th,
.ant-modal .ant-table.ant-table-bordered tfoot > tr > td {
  border-right: 1px solid #3a3a3a;
}
.ant-modal .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #3a3a3a;
}
.ant-modal .ant-table.ant-table-bordered table thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #303030;
}
.ant-modal .ant-table.ant-table-bordered .ant-table-container {
  border: 1px solid #3a3a3a;
}
.ant-modal .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
  border-right: 1px solid #3a3a3a;
}
.ant-modal .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #3a3a3a;
}
.ant-modal .ant-table .ant-table-filter-trigger-container-open {
  background-color: #525252;
}
.ant-modal .ant-picker-calendar-full {
  background-color: #1f1f1f;
}
.ant-modal .ant-picker-calendar-full .ant-picker-panel {
  background-color: #1f1f1f;
}
.ant-modal .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #3a3a3a;
}
.ant-modal .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
}
.ant-modal .ant-badge-count {
  box-shadow: 0 0 0 1px #1f1f1f;
}
.ant-modal .ant-tree-show-line .ant-tree-switcher {
  background: #1f1f1f;
}
